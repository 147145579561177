import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import Logo from "../../images/Navbar/BasmaGlobalLogo.png";
import { FaBars } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import "../../App.css";

function Navbar() {
  const location = useLocation();

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const isActive = (path) => location.pathname === path;

  return (
    <div className=" W-[100%] font-[ACaslonPro-Regular]">
      <div className=" md:hidden gap-[16%] px-[5%] flex">
        <Link to="/" className="w-[250px]">
          <img
            className="w-[100%] object-cover h-[100%]"
            src={Logo}
            alt="Logo"
          />
        </Link>
        <div className="items-center w-[600px] text-[20px] lg:text-[15px] md:hidden flex justify-evenly">
          <Link
            to="/"
            className={`hover:text-[#B8C353] cursor-pointer ${
              isActive("/") ? "text-[#B8C353]" : ""
            }`}
          >
            HOME
          </Link>
          <Link
            to="/projects"
            className={`hover:text-[#B8C353] cursor-pointer ${
              isActive("/projects") ? "text-[#B8C353]" : ""
            }`}
          >
            PROJECTS
          </Link>
          <Link
            to="/contact"
            className={`hover:text-[#B8C353] cursor-pointer ${
              isActive("/contact") ? "text-[#B8C353]" : ""
            }`}
          >
            CONTACT US
          </Link>
        </div>
        <div className="hidden md:block"></div>
      </div>

      <div className="md:flex hidden flex-col bg-bg-main shadow-sm  ">
        <div className="flex justify-between items-center px-[5%]  p-4 ">
          <div className=" h-[70px] w-[200px] flex justify-center items-center lg:w-[200px] ">
            <Link to="/">
              <img src={Logo} className="" alt="Logo" />
            </Link>
          </div>

          <div className="hidden md:flex items-center">
            {menuOpen ? (
              <MdOutlineClose
                onClick={toggleMenu}
                style={{ fontSize: 30, color: "#B8C353", zIndex: "500" }}
              />
            ) : (
              <FaBars
                onClick={toggleMenu}
                style={{ fontSize: 30, color: "#B8C353", zIndex: "500" }}
              />
            )}
          </div>
        </div>
        <div
          className={`fixed inset-0 bg-bg-main transform ${
            menuOpen ? "translate-x-0" : "-translate-x-full"
          } transition-transform duration-300 ease-in-out bg-[#e6e6e6] z-50`}
        >
          <div className="flex flex-col items-center justify-center h-full gap-10 p-5">
            <div className="flex text-[20px] flex-col items-center space-y-6">
              <Link
                to="/"
                className={`hover:text-[#B8C353] cursor-pointer ${
                  isActive("/") ? "text-[#B8C353]" : ""
                }`}
              >
                HOME
              </Link>
              <Link
                to="/projects"
                className={`hover:text-[#B8C353] cursor-pointer ${
                  isActive("/projects") ? "text-[#B8C353]" : ""
                }`}
              >
                PROJECTS
              </Link>
              <Link
                to="/contact"
                className={`hover:text-[#B8C353] cursor-pointer ${
                  isActive("/contact") ? "text-[#B8C353]" : ""
                }`}
              >
                CONTACT US
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
