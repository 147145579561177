import React from "react";
import Navbar from "../Components/Navbar/Navbar";
import ProjectsMain from "../Components/Projects/Projects-Main/ProjectsMain";

function ProjectsPage() {
  return (
    <>
      <Navbar />
      <ProjectsMain/>
    </>
  );
}

export default ProjectsPage;
