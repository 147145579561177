import React, { useState } from "react";
import logo from "../../../images/Projects/Logo_ABP_RC_White.png";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import { IoArrowBackCircleOutline } from "react-icons/io5";

function Navbar() {
  const [showAccomodation, setShowAccomodation] = useState(false);
  const [showMeeting, setShowMeeting] = useState(false);
  const [showSuits, setShowSuits] = useState(false);
  const [showRoom, setShowRoom] = useState(false);
  const [iframeSrc, setIframeSrc] = useState(
    "https://my.matterport.com/show/?m=1W1qnPWAN9F&brand=0"
  );
  const [showAccomodationResp, setShowAccomodationResp] = useState(false);
  const [showMeetingResp, setShowMeetingResp] = useState(false);
  const [showSuitsResp, setShowSuitsResp] = useState(false);
  const [showRoomResp, setShowRoomResp] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };
  const hideSideBar = () => {
    setSidebarVisible(false);
    setShowAccomodationResp(false);
    setShowMeetingResp(false);
    setShowSuitsResp(false);
    setShowRoomResp(false);
  };

  const handleSuitesClick = () => {
    setShowSuits(true);
    setShowRoom(false);
  };

  const handleRoomsClick = () => {
    setShowRoom(true);
    setShowSuits(false);
  };

  const handleMouseLeaveAccommodation = () => {
    setShowAccomodation(false);
    setShowSuits(false);
    setShowRoom(false);
  };

  const handleSuitesClickResp = () => {
    setShowSuitsResp(true);
    setShowRoomResp(false);
  };

  const handleRoomsClickResp = () => {
    setShowRoomResp(true);
    setShowSuitsResp(false);
  };
  const handleAccommodationClickResp = () => {
    setShowAccomodationResp(!showAccomodationResp);
    setShowMeetingResp(false);
  };

  const handleMeetingClickResp = () => {
    setShowMeetingResp(!showMeetingResp);
    setShowAccomodationResp(false);
  };
  const handleLinkClick = (src) => {
    setIframeSrc(`${src}&play=1`);
    hideSideBar();
  };

  return (
    <>
      <div className=" text-white z-[20]  absolute top-[0.1%]  bg-[#5f5e5e3a] px-[2%] py-[0.5%] w-[100%]">
        <Link
          to="/projects"
          className=" justify-evenly w-[80px] hover:text-[#b8c353] cursor-pointer flex items-center"
        >
          <IoArrowBackCircleOutline className="w-[20px] h-[20px]" />
          <p className=" text-white hover:text-[#b8c353]  text-[20px]">Back</p>
        </Link>

        <div className="  w-[100%] h-[auto] font-[ProximaNova-Regular]  flex justify-between items-center">
          <Link
            to="https://www.ritzcarlton.com/en/hotels/mctrz-al-bustan-palace-a-ritz-carlton-hotel/overview/?nst=paid&cid=PAI_GLB00050K5_GLE000C53T_GLF000QGQD&ppc=ppc&pId=corpimedia&gad_source=1&gclid=CjwKCAjwydSzBhBOEiwAj0XN4Fe3w7EnV9CTYBD7UR8y7CFbq7oX6ueYjX5wVIzBUPYz2QZTu90KJhoCDZUQAvD_BwE&gclsrc=aw.ds"
            className=" h-[100%] w-[150px] lg:w-[130px]"
          >
            <img
              className=" w-[100%] h-[100%] lg:object-contain "
              src={logo}
              alt="Logo"
            ></img>
          </Link>

          <div className=" text-center md:hidden w-[50%] 2xl:w-[70%] flex justify-between text-[20px] items-end h-[fit-content] relative">
            <div className=" ">
              <Link
                className="cursor-pointer hover:text-[#e0e0e0]"
                to="#"
                onClick={() =>
                  handleLinkClick(
                    "https://my.matterport.com/show/?m=1W1qnPWAN9F&brand=0"
                  )
                }
              >
                Lobby
              </Link>
            </div>
            <div
              className=" relative"
              onMouseEnter={() => setShowAccomodation(true)}
              onMouseLeave={handleMouseLeaveAccommodation}
            >
              <p className="w-[200px] cursor-pointer hover:text-[#e0e0e0] ">
                Accommodation
              </p>
              {showAccomodation && (
                <div className="absolute bg-[#808080] flex flex-col p-[1%] top-full text-[18px] left-0  w-[100%] ">
                  <p
                    onClick={handleSuitesClick}
                    className=" text-[18px] h-[50px] flex justify-center items-center cursor-pointer hover:text-[#e0e0e0]"
                  >
                    Suites
                  </p>
                  {showSuits && (
                    <div className="relative flex flex-col p-[1%] left-0  w-[100%] bg-[gray]">
                      <Link
                        to="#"
                        onClick={() =>
                          handleLinkClick(
                            "https://my.matterport.com/show/?m=CJfReBSSqjT&brand=0"
                          )
                        }
                        className="text-[12px] cursor-pointer hover:text-[#e0e0e0]"
                      >
                        Junior Suite
                      </Link>
                      <Link
                        to="#"
                        onClick={() =>
                          handleLinkClick(
                            "https://my.matterport.com/show/?m=543HajJD7rT&brand=0"
                          )
                        }
                        className="text-[12px] cursor-pointer hover:text-[#e0e0e0]"
                      >
                        Executive Suite
                      </Link>
                      <Link
                        to="#"
                        onClick={() =>
                          handleLinkClick(
                            "https://my.matterport.com/show/?m=SokJBSAfYLD&brand=0"
                          )
                        }
                        className="text-[12px] cursor-pointer hover:text-[#e0e0e0]"
                      >
                        Al Rustaq Presidential Suite
                      </Link>
                    </div>
                  )}
                  <p
                    onClick={handleRoomsClick}
                    className=" text-[18px] h-[50px] cursor-pointer hover:text-[#e0e0e0] flex justify-center items-center"
                  >
                    Rooms
                  </p>
                  {showRoom && (
                    <div className="relative flex flex-col p-[1%] left-0  w-[100%]  bg-[gray]">
                      <Link
                        to="#"
                        onClick={() =>
                          handleLinkClick(
                            "https://my.matterport.com/show/?m=ZUxd1aJsqmz&brand=0"
                          )
                        }
                        className="text-[12px] cursor-pointer hover:text-[#e0e0e0]"
                      >
                        Deluxe Sea View Room
                      </Link>
                      <Link
                        to="#"
                        onClick={() =>
                          handleLinkClick(
                            "https://my.matterport.com/show/?m=SCZJYsyEPhc&brand=0"
                          )
                        }
                        className="text-[12px] cursor-pointer hover:text-[#e0e0e0]"
                      >
                        Deluxe Mountain View Room
                      </Link>
                      <Link
                        to="#"
                        onClick={() =>
                          handleLinkClick(
                            "https://my.matterport.com/show/?m=KGrYXaBTmu4&brand=0"
                          )
                        }
                        className="text-[12px] cursor-pointer hover:text-[#e0e0e0]"
                      >
                        Deluxe Room Pool View
                      </Link>
                      <Link
                        to="#"
                        onClick={() =>
                          handleLinkClick(
                            "https://my.matterport.com/show/?m=SomFjBYzzd4&brand=0"
                          )
                        }
                        className="text-[12px] cursor-pointer hover:text-[#e0e0e0]"
                      >
                        Deluxe Room Lagoon View
                      </Link>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div
              className=" relative"
              onMouseEnter={() => setShowMeeting(true)}
              onMouseLeave={() => setShowMeeting(false)}
            >
              <p className=" cursor-pointer hover:text-[#e0e0e0] ">
                Meetings and Events
              </p>
              {showMeeting && (
                <div className="absolute text-[15px] top-full flex flex-col p-[1%] left-0  w-[100%] bg-[gray]">
                  <Link
                    to="#"
                    onClick={() =>
                      handleLinkClick(
                        "https://my.matterport.com/show/?m=B7TmfHnR5np&brand=0"
                      )
                    }
                    className=" cursor-pointer hover:text-[#e0e0e0] lg:text-[12px] h-[50px] flex justify-center items-center"
                  >
                    Qantab Meeting Room
                  </Link>
                  <Link
                    to="#"
                    onClick={() =>
                      handleLinkClick(
                        "https://my.matterport.com/show/?m=nzXfjScGaBQ&brand=0"
                      )
                    }
                    className=" cursor-pointer hover:text-[#e0e0e0] lg:text-[12px] h-[50px] flex justify-center items-center"
                  >
                    The Pearl Meeting Room
                  </Link>
                  <Link
                    to="#"
                    onClick={() =>
                      handleLinkClick(
                        "https://my.matterport.com/show/?m=etMewqChzc9&brand=0"
                      )
                    }
                    className=" cursor-pointer hover:text-[#e0e0e0] lg:text-[12px] h-[50px] flex justify-center items-center"
                  >
                    Al Majan Ballroom
                  </Link>
                </div>
              )}
            </div>
          </div>
          {/* RESPONSIVE SECTION */}
          <div className="hidden md:block">
            <div className="w-[50px]  h-[30px] " onClick={toggleSidebar}>
              <FaBars className="w-[100%] h-[100%]" />
            </div>
          </div>
          {sidebarVisible && (
            <div className="hidden md:block bg-[#808080] h-[100%] w-[50%] sm:w-[100%] absolute right-0 top-0">
              <div className="  flex justify-end items-end h-[80px] px-[1%]">
                <div
                  className="z-[20]  w-[50px] h-[40px]   "
                  onClick={hideSideBar}
                >
                  <MdOutlineClose className="w-[100%] h-[100%]" />
                </div>
              </div>
              <Link
                to="https://www.ritzcarlton.com/en/hotels/mctrz-al-bustan-palace-a-ritz-carlton-hotel/overview/?nst=paid&cid=PAI_GLB00050K5_GLE000C53T_GLF000QGQD&ppc=ppc&pId=corpimedia&gad_source=1&gclid=CjwKCAjwydSzBhBOEiwAj0XN4Fe3w7EnV9CTYBD7UR8y7CFbq7oX6ueYjX5wVIzBUPYz2QZTu90KJhoCDZUQAvD_BwE&gclsrc=aw.ds"
                className=" w-[100%] bg-[#808080] hidden sm:flex justify-center items-center "
              >
                <img
                  className=" w-[150px] lg:object-contain "
                  src={logo}
                  alt="Logo"
                ></img>
              </Link>
              <div className="mt-[12%] sm:mt-[0%] border-[black] bg-[#808080] p-[2%] w-[100%] h-[70vh] sm:h-[60vh]">
                <div className=" w-[100%] h-[100%]">
                  <div className="w-[100%] text-[25px] text-center h-[50px] flex justify-center items-center">
                    <Link
                      to="#"
                      onClick={() =>
                        handleLinkClick(
                          "https://my.matterport.com/show/?m=1W1qnPWAN9F&brand=0"
                        )
                      }
                    >
                      Lobby
                    </Link>
                  </div>
                  <div className="relative">
                    <p
                      className="w-[100%] text-[25px] text-center h-[50px] flex justify-center items-center"
                      onClick={handleAccommodationClickResp}
                    >
                      Accommodation
                    </p>
                    {showAccomodationResp && (
                      <div className="bg-[#808080] flex flex-col p-[1%] mt-[10px] w-[100%]">
                        <p
                          onClick={handleSuitesClickResp}
                          className="text-[20px] h-[50px] flex justify-center items-center cursor-pointer"
                        >
                          Suites
                        </p>
                        {showSuitsResp && (
                          <div className="flex flex-col justify-center items-center p-[1%] bg-[gray]">
                            <Link
                              to="#"
                              onClick={() =>
                                handleLinkClick(
                                  "https://my.matterport.com/show/?m=CJfReBSSqjT&brand=0"
                                )
                              }
                              className="text-[15px]"
                            >
                              Junior Suite
                            </Link>
                            <Link
                              to="#"
                              onClick={() =>
                                handleLinkClick(
                                  "https://my.matterport.com/show/?m=543HajJD7rT&brand=0"
                                )
                              }
                              className="text-[15px]"
                            >
                              Executive Suite
                            </Link>
                            <Link
                              to="#"
                              onClick={() =>
                                handleLinkClick(
                                  "https://my.matterport.com/show/?m=SokJBSAfYLD&brand=0"
                                )
                              }
                              className="text-[15px]"
                            >
                              Al Rustaq Presidential Suite
                            </Link>
                          </div>
                        )}
                        <p
                          onClick={handleRoomsClickResp}
                          className="text-[20px] h-[50px] flex justify-center items-center cursor-pointer"
                        >
                          Rooms
                        </p>
                        {showRoomResp && (
                          <div className="flex flex-col justify-center items-center p-[1%] bg-[gray]">
                            <Link
                              to="#"
                              onClick={() =>
                                handleLinkClick(
                                  "https://my.matterport.com/show/?m=ZUxd1aJsqmz&brand=0"
                                )
                              }
                              className="text-[15px]"
                            >
                              Deluxe Sea View Room
                            </Link>
                            <Link
                              to="#"
                              onClick={() =>
                                handleLinkClick(
                                  "https://my.matterport.com/show/?m=SCZJYsyEPhc&brand=0"
                                )
                              }
                              className="text-[15px]"
                            >
                              Deluxe Mountain View Room
                            </Link>
                            <Link
                              to="#"
                              onClick={() =>
                                handleLinkClick(
                                  "https://my.matterport.com/show/?m=KGrYXaBTmu4&brand=0"
                                )
                              }
                              className="text-[15px]"
                            >
                              Deluxe Room Pool View
                            </Link>
                            <Link
                              to="#"
                              onClick={() =>
                                handleLinkClick(
                                  "https://my.matterport.com/show/?m=SomFjBYzzd4&brand=0"
                                )
                              }
                              className="text-[15px]"
                            >
                              Deluxe Room Lagoon View
                            </Link>
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="relative">
                    <p
                      className="w-[100%] text-[25px] text-center h-[50px] flex justify-center items-center"
                      onClick={handleMeetingClickResp}
                    >
                      Meetings and Events
                    </p>
                    {showMeetingResp && (
                      <div className="flex flex-col p-[1%] bg-[gray]">
                        <Link
                          to="#"
                          onClick={() =>
                            handleLinkClick(
                              "https://my.matterport.com/show/?m=B7TmfHnR5np&brand=0"
                            )
                          }
                          className="text-[18px] h-[50px] flex justify-center items-center"
                        >
                          Qantab Meeting Room
                        </Link>
                        <Link
                          to="#"
                          onClick={() =>
                            handleLinkClick(
                              "https://my.matterport.com/show/?m=nzXfjScGaBQ&brand=0"
                            )
                          }
                          className="text-[18px] h-[50px] flex justify-center items-center"
                        >
                          The Pearl Meeting Room
                        </Link>
                        <Link
                          to="#"
                          onClick={() =>
                            handleLinkClick(
                              "https://my.matterport.com/show/?m=etMewqChzc9&brand=0"
                            )
                          }
                          className="text-[18px] h-[50px] flex justify-center items-center"
                        >
                          Al Majan Ballroom
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="fixed inset-0">
        <iframe
          src={iframeSrc}
          title="Meeting Room"
          width="100%"
          height="100%"
          allowFullScreen
        ></iframe>
      </div>
    </>
  );
}

export default Navbar;
