import { Route, Routes } from "react-router";
import HomePage from "./Pages/HomePage";
import ContactUsPage from "./Pages/ContactUsPage";
import ProjectsPage from "./Pages/ProjectsPage";
import Navbar from "./Components/Projects/AlBustanPalace/Navbar";



function App() {
  return (
    <div>
      <Routes>
        <Route path="/" Component={HomePage} />
        <Route path="/projects" Component={ProjectsPage} />
        <Route path="/contact" Component={ContactUsPage} />
        <Route path="/projects/albustan-palace" Component={Navbar}/>
      </Routes>
    </div>
  );
}

export default App;
