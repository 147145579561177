import { LuPhone } from "react-icons/lu";
import { AiOutlineMail } from "react-icons/ai";

function ContactUs() {
  return (
    <div className="lg:my-[5%] px-[5%] md:px-[2%]">
      <h1 className=" w-[100%] text-left  text-[4vw] lg:text-[30px] sm:text-[25px] lg:px-[1%] pt-[2%]">
       CONTACT US
      </h1>

      <div className="w-[100%] lg:h-[auto] lg:py-[2%] pt-[2%] ">
        <h1 className="text-[40px]  px-[2%] lg:text-[40px] md:text-[25px]"></h1>
        <div className=" mt-[2%]  flex  h-[350px] lg:flex-wrap justify-between gap-[2%] ">
          <div className=" w-[50%] h-[10%] lg:w-[100%]   ">
            <div className="p-[4%] rounded-[5px] border-[1px] border-[black]  h-[150px] lg:h-[150px] lg:justify-center gap-[4%] w-[100%] flex flex-col justify-between">
              <div className="flex gap-[1%]  items-center">
                <div className=" flex justify-center bg-[#b8c353] rounded-full items-center w-[60px] h-[60px] ">
                  <LuPhone className="w-[40px] h-[30px] text-white  " />
                </div>
                <div>
                  <p className=" font-[800] text-[18px]"> Phone Number</p>
                  <a
                    href="tel:+96890943093"
                    className="text-[15px] hover:text-[#b8c353] underline-animation"
                  >
                    +968 9094 3093
                  </a>

                  <style jsx>{`
                    .underline-animation {
                      position: relative;
                      display: inline-block;
                      text-decoration: none;
                    }

                    .underline-animation::after {
                      content: "";
                      position: absolute;
                      width: 100%;
                      height: 2px;
                      bottom: 0;
                      left: 0;
                      background-color: transparent;
                      visibility: hidden;
                      transform: scaleX(0);
                      transform-origin: left;
                      transition: all 0.3s ease-in-out;
                    }

                    .underline-animation:hover::after {
                      visibility: visible;
                      transform: scaleX(1);
                      background-color: #b8c353;
                    }
                  `}</style>
                </div>
              </div>
            </div>
          </div>
          <div className="  w-[50%] h-[10%] lg:w-[100%] ">
            <div className="p-[4%]  border-[1px] border-[black] rounded-[5px] h-[150px] lg:h-[150px] lg:justify-center gap-[4%] w-[100%] flex flex-col justify-between">
              <div className="flex gap-[1%]  items-center ">
                <div className=" flex justify-center bg-[#b8c353] rounded-full items-center w-[60px] h-[60px] ">
                  <AiOutlineMail className="w-[40px] h-[30px] text-white " />
                </div>
                <div>
                  <p className=" font-[800] text-[18px]">Email</p>
                  <a
                    href="mailto:wael@thecrossnature.com"
                    className="text-[15px] hover:text-[#b8c353] underline-animation"
                  >
                    wael@thecrossnature.com
                  </a>

                  <style jsx>{`
                    .underline-animation {
                      position: relative;
                      display: inline-block;
                      text-decoration: none;
                    }

                    .underline-animation::after {
                      content: "";
                      position: absolute;
                      width: 100%;
                      height: 2px;
                      bottom: 0;
                      left: 0;
                      background-color: transparent;
                      visibility: hidden;
                      transform: scaleX(0);
                      transform-origin: left;
                      transition: all 0.3s ease-in-out;
                    }

                    .underline-animation:hover::after {
                      visibility: visible;
                      transform: scaleX(1);
                      background-color: #b8c353;
                    }
                  `}</style>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
