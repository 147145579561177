import React from "react";
import omanHotel from "../../../images/Projects/OmanHotel.png";
import muscat from "../../../images/Projects/muscat.png";
import mysk from "../../../images/Projects/MYSK.png";
import sheraton from "../../../images/Projects/sheraton.png";
import bustanPalace from "../../../images/Projects/AlBustanPalace.png";
import alsharqiya from "../../../images/Projects/alsharqiya.jpg";
import crownPlaca from "../../../images/Projects/crownPlaza(IHG).png";
import plaza from "../../../images/Projects/plaza.jpg";
import { Link } from "react-router-dom";
function ProjectsMain() {
  return (
    <div className="lg:my-[5%] px-[5%] md:px-[2%]">
      <div>
        <h1 className="pt-[2%] w-[100%] text-left text-[4vw] lg:text-[30px] sm:text-[25px] lg:px-[1%]">
          OUR PROJECTS
        </h1>
        <div className="text-[20px] sm:text-[15px] xl:w-[100%] w-[80%] text-left">
          <p>
            We are committed to sustainability and responsibility, striving to
            minimize physical footprints through virtual experiences,
            contributing to a greener, more eco-conscious world. Our
            customer-centric approach is focused on understanding our client's
            unique needs and tailoring our services to exceed expectations,
            building long-lasting partnerships, and delivering remarkable value.
            Despite our advanced technology, we never lose sight of the human
            element; our vision embraces using the latest technology to foster
            meaningful connections, bridging distances, and enhancing
            communication, creating a world that feels closer than ever before.
          </p>
        </div>
        <div>
          <div className="list-decimal pl-5 text-[20px] sm:text-[15px]">
            <div className=" flex-wrap flex justify-evenly items-center">
              <Link to="/projects/albustan-palace">
                <img
                  src={bustanPalace}
                  className=" cursor-pointer w-[250px] h-[200px]"
                  alt=""
                />
              </Link>

              <img src={mysk} alt="" />
              <img src={muscat} alt="" />
              <img src={omanHotel} className=" w-[220px] h-[200px]" alt="" />
            </div>
            <div className=" flex-wrap flex justify-evenly items-center sm:mt-[2%]">
              <img src={crownPlaca} className=" w-[220px] h-[200px]" alt="" />
              <img src={sheraton}></img>
              <img src={plaza} alt="" />
              <img className=" w-[250px] h-[130px]" src={alsharqiya} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectsMain;
