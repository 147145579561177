import React from "react";
import Navbar from "../Components/Navbar/Navbar";
import ContactUs from "../Components/ContactUs/ContactUs";

function ContactUsPage() {
  return (
    <>
      <Navbar />
      <ContactUs/>
    </>
  );
}

export default ContactUsPage;
