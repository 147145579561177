import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../index.css";

import Navbar from "../Navbar/Navbar";
import hand from "../../images/Slider/slide-1.png";
import mission from "../../images/Slider/slide-2.png";
import vission from "../../images/Slider/slide-3.png";
function Slider() {
  return (
    <div className=" overflow-hidden h-[100vh] lg:h-[auto] ">
      
      <Swiper
        className="mySwiper"
        loop={true}
        centeredSlides={true}
        autoplay={{
          delay: 5500,
          disableOnInteraction: false,
        }}
        slidesPerView={1}
        spaceBetween={30}
        modules={[Autoplay, Pagination, Navigation]}
      >
        <SwiperSlide className=" lg:my-[5%] px-[5%] md:px-[2%]">
          <h1 className=" w-[100%] text-left  text-[4vw] lg:text-[30px] sm:text-[25px] lg:px-[1%] pt-[2%]">
            IMMERSIVE TECHNOLOGY
          </h1>
          <div className="  flex justify-evenly xl:justify-end xl:py-[2%] h-[100%] xl:flex-col-reverse ">
            <div className="xl:w-[100%] w-[60%] h-[80%] xl:h-[60%] md:w-[100%] md:h-[100%]">
              <img className=" h-[100%] w-[100%] images " src={hand}></img>
            </div>
            <div className="  text-[20px] sm:text-[15px] xl:w-[100%] w-[30%] text-left">
              <p className="  xl:w-[90%]  md:w-[100%]  w-[450px] ">
                Creating Unforgettable Reality Tours with 3D Virtual
                Cutting-Edge Technology. Using top- of-the-line technology, we
                meticulously scan and render spaces to bring them to life with
                incredible detail and realism.
              </p>
              <p className="  xl:w-[90%]  md:w-[100%]  w-[450px] pt-[2%] ">
                We are committed to sustainability and responsibility, striving
                to minimize physical footprints through virtual experiences,
                contributing to a greener, more eco-conscious world.
              </p>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide className="lg:my-[5%] px-[5%] md:px-[2%] ">
          <h1 className="  pt-[2%] w-[100%] text-left  text-[4vw] lg:text-[30px] sm:text-[25px] lg:px-[1%]">
            OUR VISSION
          </h1>
          <div className="  flex flex-row-reverse justify-evenly xl:justify-center xl:items-center h-[100%] xl:flex-col-reverse ">
            <div className="xl:w-[100%] w-[50%] h-[80%] md:w-[100%] md:h-[100%]">
              <img
                className=" h-[100%] w-[100%] images rotate-2"
                src={mission}
              ></img>
            </div>
            <div className="  text-[20px] sm:text-[15px] xl:w-[100%] w-[49%] text-left">
              <p className="  xl:w-[90%]  md:w-[100%]  w-[450px] ">
                We aim to revolutionize how stories are told in the digital age,
                bringing spaces to life through captivating 3D environments that
                empower businesses to showcase their offerings with
                authenticity.
              </p>
              <p className="  xl:w-[90%]  md:w-[100%]  w-[450px] pt-[2%]  ">
                Our vision embraces a sustainable, eco-conscious world where
                virtual and physical realities coexist seamlessly, inspiring a
                new era of creativity and human connection. We aspire to leave
                an indelible mark on the world of immersive experiences,
                creating lasting partnerships and delivering remarkable value to
                our clients.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="lg:my-[5%] px-[5%] md:px-[2%] ">
          <div className=" py-[2%] flex justify-evenly xl:justify-center xl:items-center h-[100%] xl:flex-col-reverse ">
            <div className="xl:w-[100%] w-[60%] h-[80%] md:w-[100%] md:h-[100%]">
              <img className=" h-[100%] w-[100%] images " src={vission}></img>
            </div>
            <div className=" text-[20px] sm:text-[15px] h-[75%] xl:w-[100%] w-[30%] text-left  flex flex-col justify-end">
              <h1 className="  pt-[2%] w-[100%] text-left  text-[4vw] lg:text-[30px] sm:text-[25px] lg:px-[1%]">
                OUR MISSION
              </h1>
              <p className=" md:w-[100%]  ">
                Our mission is to be at the forefront of technological
                innovation and excellence, revolutionizing industries through
                immersive Technology experiences.
              </p>
              <p className=" md:w-[100%]  pt-[2%] ">
                We break down geographical barriers, enabling global
                accessibility, while fostering a collaborative ecosystem that
                empowers creativity and embraces sustainability.
              </p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default Slider;
